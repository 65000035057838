import React, { Component } from 'react';
import { Row} from 'react-bootstrap'

class HomePage extends Component {
componentDidMount(){
    window.scrollTo(0, 0);

}
    render() {   

        return ( 
            <div style={{minHeight: '100%'}}>            
                <div style={{background:'white', paddingTop: '10px', paddingBottom: '150px',maxWidth: '1280px', margin: 'auto'}}>
                    <div style={{margin: 'auto',width: '75%', textAlign: 'center',paddingBottom: '10px'}} id="partner">
                        <Row style={{padding: '10px'}}>
                            <h2 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Become a website developer partner</u>
                            </h2>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>What is it?</h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>Our partner programme gives our partners exclusive hosting discounts for their customers, not only making their customers happy but incentivising new customers for that partner. You wont be under strict conditions or policies,
                            you only need to keep your customers happy creating amazing websites and acting with good standards. Each of your customers will receive 6 months free on any hosting plan through a coupon code we will give to you. We also hold regular competions and showcases for our partners. 
                            Whats not to like? Apply today!</p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>Who can become one?</h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Whether your a full time websites developer freelancer or a hobbyist website developer, we cater to everyone. You can have anything from one customer to houndreds. Our platform scales to meet your needs.
                                The only thing you need to apply is a knowledge of one of our supported website types and at least one example of a website youve created. Dont have one? Simply create a demo website with us and we will see if its up to standard.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>How do I become one?</h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>Simply email us at <a href="mailto: breezelabsuk@gmail.com">breezelabsuk@gmail.com</a> with a link to a website that youve created and any relevant experience you have. PS. dont forget to state that you are applying to 
                            become a website developer partner.</p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>How can I ensure one of your partners is legit?</h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>All our partners are held to strict rules on keeping there customers happy. If you are unhappy with a partner, simply email us at <a href="mailto: breezelabsuk@gmail.com">breezelabsuk@gmail.com</a> and tell us whats happened.
                            If we find that partner has acted in bad standards, we will remove all partner priveleges from that partner.</p>
                        </Row>
                    </div>
                </div>                
            </div>
        );
    }
}
 
export default HomePage;
