import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap'
import {Link} from "react-router-dom"

class HomePage extends Component {
    state = {  }
    scroll = () => {
        let element = document.getElementById("content")
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    }
    componentDidMount(){
        window.scrollTo(0, 0);

    }
    render() { 
      var robotImg = require('../robot-2791671_1920.jpg')
        var hostingImg = require('../hosting_plan_page.png')
       var printerImg = require('../printer-2416270_1920.jpg')
        
        return ( 
            <div style={{minHeight: '100%'}}>
                <div style={{margin: 'auto', height: 'auto', width: '100%', position: 'relative', textAlign: 'center', background: '#212529'}}>
                    <img alt='banner' style={{maxWidth: '100%', maxHeight: '720px'}}  src={require('../laptop-120900.jpg')}></img>                    
                </div>
                <div style={{background:'white', paddingTop: '10px',paddingBottom: '150px',maxWidth: '1280px', margin: 'auto'}}>
                    <div style={{margin: 'auto',width: '97%', textAlign: 'center'}}>
                        <Row >
                            <u style={{margin:'auto', textAlign: 'center'}}><h1>Our Services</h1></u>
                        </Row>
                   
                        {/* <hr/> */}
                        <Row>
                        <Col style={{marginTop:'20px', maxWidth: "1050px", paddingBottom: "20px", minWidth: "320px",background: "#343a40", color: "white", borderRadius: "25px", marginLeft: "20px", marginRight: "20px"}}>
                            <Row style={{margin: 'auto', textAlign: 'center'}}>
                                <h3 style={{ margin: 'auto', textAlign: 'center'}}><u>Hosting</u></h3>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto', textAlign: 'center'}}>
                                <img style={{ maxWidth: '420px', minWidth: '250px', margin: 'auto'}} alt='serverImage' src={hostingImg}></img>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Creating and maintaining a website shouldnt be difficult or expensive. Thats why we've custom made our hosting control panel to give you all the tools you need to create your website in the best possible way. With low costs and no hidden fees, why wouldnt you host with us?</p>
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                            <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Along with your standard website hosting tools, we provide integrated backups, email hosting and an amazing customer support team. With support for Wordpress and WikiDoku, theres no limits to the website you can create.</p>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Click on the button below to check out our hosting service.</p>
                            </Row> 
                            <Row style={{minWidth: '250px', maxWidth: '420px',textAlign: 'center', margin: 'auto', marginTop: '15px'}}>
                                <Button as={Link} to='/hosting' style={{backgroundColor: "rgb(0, 123, 255)", margin: 'auto', width: '100%'}}>Hosting</Button>
                            </Row>  
                        </Col>
                        <Col style={{marginTop:'20px', maxWidth: "1050px", paddingBottom: "20px", minWidth: "320px",background: "#343a40", color: "white", borderRadius: "25px", marginLeft: "20px", marginRight: "20px"}}>
                            <Row style={{minWidth: '250px', maxWidth: '420px', margin: 'auto', textAlign: 'center'}}>
                                <h3 style={{ margin: 'auto', textAlign: 'center'}}><u>On Demand Manufacturing</u></h3>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto', textAlign: 'center'}}>
                                <img style={{ maxWidth: '420px', minWidth: '255px', margin: 'auto'}} alt='printerImage' src={printerImg}></img>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Have you got an idea for a DIY project that you want made at an affordable price?</p>                           
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Have you got a prototype product that you want created one off?</p>
                                                        
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Have you got a product you want produced at scale?</p>                 
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}>Our simple and intuitive web console gives you access to easy and affordable 3D Printing and CNC Machining, whether its just a one off prototype or a product to be manufactured at scale.</p>
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <b style={{minWidth: '250px', maxWidth: '420px', margin: 'auto'}}><p>Coming Soon</p></b>       
                            </Row> 
                            <Row style={{minWidth: '250px', maxWidth: '420px',textAlign: 'center', margin: 'auto'}}>
                                <Button as={Link} to='/on-demand-manufacturing' style={{backgroundColor: "rgb(0, 123, 255)", margin: 'auto', width: '100%'}}>On Demand Manufacturing</Button>
                            </Row>  
                        </Col>


                        <Col style={{marginTop:'20px', maxWidth: "1050px", paddingBottom: "20px", minWidth: "320px",background: "#343a40", color: "white", borderRadius: "25px", marginLeft: "20px", marginRight: "20px"}}>
                            <Row style={{margin: 'auto', textAlign: 'center'}}>
                                <h3 style={{ margin: 'auto', textAlign: 'center'}}><u>Modular Robotics</u></h3>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto', textAlign: 'center'}}>
                                <img style={{ maxWidth: '420px', minWidth: '255px', margin: 'auto'}} alt='robotImage' src={robotImg}></img>
                            </Row>
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>
                                <p style={{margin: 'auto'}}>Our innovative modular robotics system breaks down the barriers to robot adoption through groundbreaking prices, limitless applications and being more user friendly while being more environmentally friendly.</p>
                            </Row> 
                            <Row style={{maxWidth: '1000px', margin: 'auto'}}>            
                                <b style={{margin: 'auto'}}><p >Coming Soon</p></b>                               
                            </Row> 
                            <Row style={{minWidth: '250px', maxWidth: '420px',textAlign: 'center', margin: 'auto'}}>
                                <Button as={Link} to='/modular-robotics' style={{backgroundColor: "rgb(0, 123, 255)", margin: 'auto', width: '100%'}}>Modular Robotics</Button>
                            </Row>  
                        </Col>
                        </Row>
                    </div>
                </div> 
            </div>
         );
    }
}
 
export default HomePage;
